import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <h1>Dogs Dicks &amp; Donuts</h1>
        <p style={{
          color: "#ffc800",
          fontSize: "72pt",
          fontWeight: "900",
          marginTop: "200px"
        }}>Coming Soon!</p>
      </div>
      <div className="footer">
        <div className='footerText'>©Copyright 2023 dogsdicksanddonuts.com. All rights reserved.</div>
        <div className='footerText'>Powered by Steve Tramposch</div>
      </div>
    </div>
  );
}

export default App;
